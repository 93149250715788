<template>
    <div>

        <div class="cards">

           <div class="card">
                <div class="title">最近30天提问者数量</div>
                <Bar id='bar1' title="最近30天提问者数量" :list='userList30' />
            </div>

           <div class="card">
                <div class="title">最近30天问答数量</div>
                <Bar id="bar2" title="最近30天问答数量" :list='questionList30' />
            </div>


            <div class="card">
                <div class="title">各部门问答数量</div>
                <Pie :list='deptList' />
            </div>

                 <div class="card">
                <div class="title">提问者数量</div>
              <a-table
        bordered
        :data-source="userList"
        :loading="loading"

      >
        <a-table-column title="提问者" data-index="name" align="center"></a-table-column>
        <a-table-column title="提问数量" data-index="value" align='center'></a-table-column>
      </a-table>

            </div>

        </div>
  

    </div>
</template>

<script>
import Pie from './pie.vue'
import Bar from './bar.vue'
import request from "@/api/request";
function fetchStat() {
  return request({
    url: "/office-service/chat/history/stat",
  });
}

export default {
    components: {
        Bar,
        Pie,
    },
    data() {
        return {
            loading: false,
            userList30: [],
            questionList30: [],
            userList: [],
            deptList: [],
        }
    },

    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            fetchStat().then(res => {
                const {dateProblem, dateUser, userStat, deptStat} = res;

         if (dateUser && typeof dateUser === 'object') {
                    this.userList30 =  Object.keys(dateUser).map(key => {
                        return {
                            name: key,
                            value: dateUser[key]
                        }
                    })
                }
                         if (dateProblem && typeof dateProblem === 'object') {
                    this.questionList30 =  Object.keys(dateProblem).map(key => {
                        return {
                            name: key,
                            value: dateProblem[key]
                        }
                    })
                }

                 if (userStat && typeof userStat === 'object') {
                    this.userList =  Object.keys(userStat).map((key,index) => {
                        return {
                            key: index,
                            name: key,
                            value: userStat[key]
                        }
                    }).sort((x, y) => y.value - x.value)
                }

                if (deptStat && typeof deptStat === 'object') {
                    this.deptList =  Object.keys(deptStat).map(key => {
                        return {
                            name: key,
                            value: deptStat[key]
                        }
                    })
                }
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>


<style lang="less" scoped>
.cards {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:1px;
    background-color: #f0f0f0;

    .card {
        background-color: #fff;
        padding: 8px;
        .title {
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 8px;
        }
    }
}
</style>