<template>
  <div>
    <div :id="id"></div>
  </div>
</template>
  
  
<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

export default {
  props: {
    id: {
        type: String,
    },
    title: {
        type: String,
    },
    list: {
      type: Array
    }
  },

  watch: {
    list: {
      handler() {
        this.renderChart();
      },
      deep: true
    }
  },

  mounted() {
    var chartDom = document.getElementById(this.id);
    chartDom.style.height = "30vh";
    chartDom.style.width = "100%";
    this.chart = echarts.init(chartDom);
    this.renderChart();
  },

  destroyed() {
    this.chart?.dispose();
  },

  methods: {
    renderChart() {
      if (!Array.isArray(this.list)) return;

      this.chart?.clear();

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
          // valueFormatter: (value) => value
        },

        legend: {
          left: "center",
          top: "bottom",
          textStyle: {
            // color: "#999",
          }
        },

        grid: {
          show: false,
          left: "7%",
          top: "15%",
          bottom: "20%",
          right: "3%"
        },

        xAxis: {
          type: "category",
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            // color: "#999",
          },
          data: this.list.map(item => item.name)
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#999"
            }
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#999"
            }
          },
          axisLabel: {
            // color: "#999",
            formatter: "{value}"
          }

          // ...yAxis,
        },

        series: [
          {
            name: this.title,
            type: "bar",
            data: this.list.map(item => item.value),
            label: {
              show: false,
              formatter: "{c}",
              position: "top",
              color: "#999"
            }
          },
        ]
      };

      option && this.chart.setOption(option);
    }
  }
};
</script>