<template>
  <div>
    <Pane />

    <a-card class="container">

      <Analysis />

      <Padding />
      <Padding />

          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input v-model="form.deptName" placeholder="部门名" style="width: 150px"></a-input>
            </a-form-model-item>
     <a-form-model-item>
              <a-input v-model="form.userName" placeholder="姓名" style="width: 150px"></a-input>
            </a-form-model-item>


          <a-form-model-item>
                          <a-range-picker v-model="form.date" style="width: 300px" />
            </a-form-model-item>
       

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>

      <Padding />
      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="部门" data-index="deptName" align="center" width='120px'></a-table-column>
        <a-table-column title="提问者" data-index="userName" align="center" width='120px'></a-table-column>
        <a-table-column title="问题" data-index="question" ></a-table-column>
        <a-table-column title="模型" data-index="robotName" align="center" width='120px'></a-table-column>

        <a-table-column title="问答时间" data-index="chatTime" align="center" width='160px'></a-table-column>
        <a-table-column title="操作" align="center" width='120px'>
          <template slot-scope="text">
            <a-space>
            <a href="#" @click.prevent="seeDetail(text)">答案</a>
            <a href="#" class="danger" @click.prevent="remove(text)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>

      <a-modal title="详情" :visible="visible" :footer="null" @cancel="cancel" width='800px'>
        <div v-html="renderContent( detail.content)"></div>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import Analysis from './components/analysis.vue'
import MarkdownIt from "markdown-it";

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true
});



function fetchHistory(data) {
  return request({
    url: "/office-service/chat/history/list",
    method: "post",
    data
  });
}

function del(data) {
  return request({
    url: "/office-service/chat/history/del",
    method: "post",
    data
  });
}

export default {
  mixins: [watermark],

  components: {
    Analysis,
  },

  data() {
    return {
      form: {},

      loading: false,
      list: [],
      total: 0,
      current: 1,
      pageSize: 10,

      visible: false,
      detail: {},
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
        renderContent(str) {
            return str ? md.render(str) : ''
    },
    getList() {
      this.loading = true;

                    let fromTime, endTime;
        if (Array.isArray(this.form.date) && this.form.date.length === 2) {
          fromTime = this.form.date[0].format("YYYY-MM-DD HH:mm:ss");
          endTime = this.form.date[1].format("YYYY-MM-DD HH:mm:ss");
        }

      fetchHistory({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
        date: undefined,
        fromTime,
        endTime,
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    seeDetail(text) {
      this.detail = text;
      this.visible = true;
    },
    cancel() {
      this.visible = false;
    },

    remove(text) {
            const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          del({
            idList:  [text.id]
          }).then(() => {
            that.getList();
          });
        }
      });
    }
  }
};
</script>
